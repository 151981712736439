import ship from './ship.gif';
import logo from './flamingLogo.gif';
import guy from './csgoGuy.gif';
import './App.css';
import React, { useState, useEffect, resize } from 'react'

function App() {
  return (
    <div style={{
      display: "flex",
      alignItems: "center", 
      flexDirection: "column",
      width: "100vw",
      height: "100vh",
      backgroundColor: "#212020",
      color: "#212020",
      backgroundImage: `url(${ship})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat"
    }}>
      <img src={logo} style={{width: "70%"}} alt="Logo"></img>
      {/* <img src={ship} style={{width: "50%"}} alt="Ship"></img> */}
    </div>
  );
}

export default App;
